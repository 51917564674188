<template>
  <b-modal
    id="edit-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        編輯地點
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 地點名稱 -->
        <validation-provider
          #default="{ errors }"
          name="地點名稱"
          rules="required"
        >
          <b-form-group label-for="name">
            <template #label>
              <label class="mb-0">
                地點名稱
                <span class="text-danger">*</span>
              </label>
            </template>

            <b-form-input
              id="name"
              v-model="placeData.name"
              autofocus
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="placeDataItem.name ? placeDataItem.name : '請輸入管理者名稱'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <div>
          <label>地點說明</label>
          <b-form-textarea
            v-model="placeData.description"
            :placeholder="placeDataItem.description ? placeDataItem.description : '請輸入地點說明'"
            :state="placeData.description.length <= maxChar ? null : false"
            rows="5"
          />
          <small
            class="textarea-counter-value float-right"
            :class="placeData.description.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ placeData.description.length }}</span> / {{ maxChar }}
          </small>
        </div>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
// API
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// UI
import {
  BForm, BFormGroup, BFormInput, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// Component
import formValidation from '@core/comp-functions/forms/form-validation'
import { usePlaceList, usePlaceSetting } from '../usePlace'
import { useAlert } from '@/libs/mixins/index'
// import store from '@/store'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BFormTextarea,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    placeDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      missingFields: [],
      maxChar: 255,
    }
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetPlaceData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('edit-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '地點名稱',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.placeData[Object.keys(requiredFields)[i]] === null || this.placeData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            lable: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.placeData.description.length > this.maxChar) {
        this.useAlertToast(false, '地點說明字數超過上限')
        return
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
        return
      }

      this.isBusy = true

      this.setPlaceUpdate({
        place_id: this.placeDataItem.id,
        data: {
          ...this.placeData,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.$emit('refetch-data')
          this.resetPlaceData()
          this.$nextTick(() => {
            this.$bvModal.hide('edit-modal')
          })
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankPlaceData, this.placeDataItem),
      }
      this.placeData = resolveData
      this.$bvModal.show('edit-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      syncObject,
    } = usePlaceSetting()

    const {
      isBusy,
      setPlaceUpdate,
    } = usePlaceList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankPlaceData = {
      name: null,
      description: '',
    }

    const placeData = ref(JSON.parse(JSON.stringify(blankPlaceData)))

    const resetPlaceData = () => {
      placeData.value = JSON.parse(JSON.stringify(blankPlaceData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetPlaceData)

    return {
      isBusy,
      syncObject,

      placeData,
      blankPlaceData,
      setPlaceUpdate,
      useAlertToast,
      useHttpCodeAlert,

      resetPlaceData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>
